import React, { useState } from "react";
import CommonHeadings from "./commonHeadings";
import "../common.css";
import { ContactLinks } from "./datas";
import AOS from "aos";
import "aos/dist/aos.css";
import ContactForm from "./form";

AOS.init();
const Contact = () => {
  const [link, setLink] = useState(false);

  return (
    <>
      <div id="contact" className="contactMain">
        <CommonHeadings
          commonheadingbgtxt="CONTACT "
          commonheading="Get In Touch"
        />
        <div className="ContactBody">
          <div className="ContactBodyLeft" data-aos="fade-up">
            <h3>ADDRESS</h3>
            <p>
              Pernambut - 635810
              <br />
              TamilNadu.
            </p>

            <div className="ContactSubDiv">
              <p>
                <i
                  className="fa-solid fa-phone-flip"
                  style={{ color: "#20c997" }}
                ></i>
                <span>9112881083</span>
              </p>
              <p>
                <i
                  className="fa-solid fa-envelope"
                  style={{ color: " #20c997" }}
                ></i>
                <span>shariquemangani03@gmail.com</span>
              </p>
            </div>
            <h3>FOLLOW ME</h3>
            <div className="socialMediaDiv">
              {ContactLinks?.map((types, i) => {
                return (
                  <a
                    href={types.location}
                    target="_blank"
                    title={types.title}
                    onMouseOver={() => setLink(i)}
                    onMouseOut={() => setLink(false)}
                  >
                    <img
                      src={link === i ? types.img2 : types.img1}
                      style={{ width: "20px" }}
                      alt="image"
                    />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="ContactBodyRight" data-aos="fade-up">
            <h3>SEND US A NOTE</h3>
            <div className="ContactRightSub">
              <ContactForm />
            </div>
          </div>
        </div>
        <div className="footer2">
          <p>
            Copyright © 2022 <span> Sharique</span>. All Rights Reserved.
          </p>
          <p>Terms & Policy | Disclaimer</p>
        </div>
      </div>
    </>
  );
};

export default Contact;
