import React from "react";
import CommonHeadings from "./commonHeadings";
import ImageGallary from "./imgGallary";
import "../components/imgGallary/imageGallary.css";

const Portfolio = () => {
  //   const imageData = [];

  return (
    <>
      <div id="portfolio" className="portfolioMain">
        <CommonHeadings
          commonheadingbgtxt="PORTFOLIO "
          commonheading="My Work"
        />
        {/* <ParallaxComponent /> */}

        <>
          {/* <div className="image-gallery" style={galleryStyles}>
            {filteredImages.map((path, i) => (
              <div className="inside-image" style={{ width: "30%" }}>
                <img src={path.image} alt="" style={imageStyles} />
              </div>
            ))}
          </div>
          <div>
            {colors.map((color) => (
              <ColorButton
                key={color}
                color={color}
                setSelectedColor={setSelectedColor}
              />
            ))}
          </div> */}
          <ImageGallary />
        </>
      </div>
    </>
  );
};

export default Portfolio;
