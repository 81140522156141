import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MainContent from "./mainContent";
import "../common.css";
import { Link } from "react-scroll";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import DescriptionIcon from "@mui/icons-material/Description";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import RateReviewIcon from "@mui/icons-material/RateReview";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import Myphoto from "../images/Profile.jpg";
import { useMediaQuery } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MiniDrawer = () => {
  const [open, setOpen] = useState(true);
  const mobileSidebar = useMediaQuery("(max-width: 480px)");
  const matches = useMediaQuery("(min-width:1000px)");

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (matches) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [matches]);

  const sidebarData = [
    {
      name: "Home",
      title: "Home",
      icon: <HomeIcon className="sideBarContent" style={{ color: "white" }} />,
      scrollTarget: "homeSection",
    },
    {
      name: "About Me",
      title: "About Me",
      icon: (
        <PersonIcon className="sideBarContent" style={{ color: "white" }} />
      ),
      scrollTarget: "aboutSection",
    },
    {
      name: "What I Do",
      title: "What I Do",
      icon: <WorkIcon className="sideBarContent" style={{ color: "white" }} />,
      scrollTarget: "whatIDo",
    },
    {
      name: "Resume",
      title: "Resume",
      icon: (
        <DescriptionIcon
          className="sideBarContent"
          style={{ color: "white" }}
        />
      ),
      scrollTarget: "resume",
    },
    {
      name: "Portfolio",
      title: "Portfolio",
      icon: (
        <WorkOutlineIcon
          className="sideBarContent"
          style={{ color: "white" }}
        />
      ),
      scrollTarget: "portfolio",
    },
    {
      name: "Testimonial",
      title: "Testimonial",
      icon: (
        <RateReviewIcon className="sideBarContent" style={{ color: "white" }} />
      ),
      scrollTarget: "testimonial",
    },
    {
      name: "Contact",
      title: "Contact",
      icon: (
        <MailOutlineIcon
          className="sideBarContent"
          style={{ color: "white" }}
        />
      ),
      scrollTarget: "contact",
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={open} className="scrollNone">
        <div className="sideBarMain">
          <div className="hamBtnDiv">
            <IconButton
              color="inherit"
              aria-label="toggle drawer"
              onClick={handleDrawerToggle}
            >
              {open ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          </div>
          <div className="profileImgDiv">
            <img src={Myphoto} alt="" className="profilePhoto" />
          </div>
          {/* <h1 className="MyName">Sharique Mangani</h1> */}
          <List style={{ background: "#111418" }}>
            {sidebarData?.map((e, i) => (
              <ListItem
                key={e.name}
                title={e.title}
                disablePadding
                sx={{ display: "block" }}
                style={{ wordSpacing: "0" }}
              >
                {e.scrollTarget ? (
                  <Link
                    smooth={true}
                    onClick={() => {
                      if (mobileSidebar && open) {
                        setOpen(!open);
                      }
                    }}
                    duration={500}
                    offset={-50}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      color: "inherit",
                    }}
                    to={e.scrollTarget}
                  >
                    <ListItemButton
                      className="sideBarContent"
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {e.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={e.name}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                ) : (
                  <ListItemButton
                    className="sideBarContent"
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {e.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={e.name}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
      <Box
        component="main"
        sx={
          mobileSidebar && open
            ? { flexGrow: 1, display: "none" }
            : { flexGrow: 1 }
        }
        className="mainContentParent"
      >
        <MainContent />
      </Box>
    </Box>
  );
};

export default MiniDrawer;
