import React from "react";
import "../common.css";
import { ServiceIcons } from "./datas";
import CommonHeadings from "./commonHeadings";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const WhatIDo = () => {
  return (
    <>
      <div id="whatIDo" className="WhatIDoMain">
        <CommonHeadings
          commonheadingbgtxt="SERVICES"
          commonheading="What I Do?"
        />
        <div className="WhatIDoContent">
          {ServiceIcons?.map((content) => {
            return (
              <div className="WhatIDoContentCommon" data-aos="fade-left">
                <div className="whatIDoIconDiv">
                  <i
                    className={content.className}
                    style={{ color: "#20c997" }}
                  ></i>
                </div>
                <div className="whatIDoContentSub">
                  <h1>{content.topic}</h1>
                  <p>{content.content}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default WhatIDo;
