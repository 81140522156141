import React, { useState, useEffect } from "react";
import "./imageGallary.css"; // Make sure to use the correct CSS file name
import QuizeAppImg from "../../images/projects/QuizeAppImg.png";
import nextPaymentImg from "../../images/projects/nextPaymentImg.png";
import homeOneAgencyImg from "../../images/projects/homeOneAgencyImg.png";
import portoImg from "../../images/projects/portoImg.png";
import nikeWebsiteImg from "../../images/projects/nikeWebsiteImg.png";
const ImageGallary = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const nextSlide = () => {
    setSlideIndex((prev) => (prev === 4 ? 0 : prev + 1));
    showSlides();
  };

  const prevSlide = () => {
    setSlideIndex((prev) => (prev === 0 ? 4 : prev - 1));
    showSlides();
  };

  const showSlides = () => {
    const slides = document.querySelectorAll(".mySlides");
    console.log("slideIndex", slideIndex);

    slides.forEach((slide) => {
      slide.style.display = "none";
    });
    slides[slideIndex].style.display = "block";
  };

  useEffect(() => {
    showSlides();
  }, [slideIndex]);

  const imageData = [
    {
      id: 1,
      img: QuizeAppImg,
      projectLink: "https://shariquemangani.github.io/Quiz-app/",
    },
    {
      id: 2,
      img: nextPaymentImg,
      projectLink:
        "https://shariquemangani.github.io/Next-generation-payment/#scrollspyHeading1",
    },
    {
      id: 3,
      img: homeOneAgencyImg,
      projectLink: "https://shariquemangani.github.io/Home-One-Agency/",
    },
    {
      id: 4,
      img: portoImg,
      projectLink: "https://shariquemangani.github.io/porto/",
    },
    {
      id: 5,
      img: nikeWebsiteImg,
      projectLink:
        "https://shariquemangani.github.io/Nike-Store-E-Commerce-Website/",
    },
  ];

  return (
    <>
      <div className="sliderParent">
        <div className="carousel-container">
          {imageData.map((slideNumber) => (
            <div
              key={slideNumber.id}
              className={`mySlides animate ${
                slideIndex === slideNumber.id - 1 ? "active" : ""
              }`}
            >
              <a href={slideNumber.projectLink} target="_black">
                <img src={slideNumber.img} alt={`Slide ${slideNumber.id}`} />
              </a>
              <div className="slidetext">{slideNumber.id}</div>
            </div>
          ))}

          {/* Next and previous buttons */}
          <a className="prev" onClick={prevSlide}>
            &#10094;
          </a>
          <a className="next" onClick={nextSlide}>
            &#10095;
          </a>
        </div>
      </div>
    </>
  );
};

export default ImageGallary;
