import "../common.css";
import ClientSlider from "./clientTestimonial";
import CommonHeadings from "./commonHeadings";

const Testimonial = () => {
  return (
    <>
      <div id="testimonial" className="testimonialMain">
        <CommonHeadings
          commonheadingbgtxt="TESTIMONIAL "
          commonheading="Client Speak"
        />
        <div className="sliderParent">
          <ClientSlider />
        </div>
      </div>
    </>
  );
};

export default Testimonial;
