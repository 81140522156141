import React, { useEffect, useState } from "react";
import "../common.css";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const Counter = () => {
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const oTop = document
        .getElementById("counter")
        .getBoundingClientRect().top;

      if (!animationStarted && oTop < window.innerHeight) {
        document.querySelectorAll(".counter").forEach((element) => {
          const startValue = parseInt(element.innerText, 10);
          let currentCount = 0;

          const updateCounter = () => {
            if (currentCount <= startValue) {
              element.innerText = Math.ceil(currentCount) + "+";
              currentCount += startValue / 200; // You can adjust the speed by changing the divisor
              requestAnimationFrame(updateCounter);
            }
          };

          updateCounter();
        });

        setAnimationStarted(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [animationStarted]);

  return (
    <section id="counter" className="">
      <div className="counterParent" data-aos="fade-up">
        <div className="counterChild" data-aos="fade-up">
          <h4 className="counter">10+</h4>
          <p>Projects Done</p>
        </div>
        <div className="counterChild" data-aos="fade-up">
          <h4 className="counter">1+</h4>
          <p>Years Experience</p>
        </div>
        <div className="counterChild" data-aos="fade-up">
          <h4 className="counter">10+</h4>
          <p>Happy Clients</p>
        </div>
        <div className="counterChild" data-aos="fade-up">
          <h4 className="counter">2+</h4>
          <p>Get Awards</p>
        </div>
      </div>
    </section>
  );
};

export default Counter;
