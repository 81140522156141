// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Button } from "@mui/material";
function ContactForm() {
  const [state, handleSubmit] = useForm("mvoerabe");
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <form onSubmit={handleSubmit} method="post">
      <div className="ContactRightInput">
        <input
          id="name"
          type="name"
          name="name"
          placeholder="Enter your name"
        />
        <ValidationError prefix="Name" field="name" errors={state.errors} />
      </div>
      <div className="ContactRightInput">
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Enter e-mail"
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>
      <div className="ContactRightInput">
        <textarea
          id="message"
          name="message"
          cols="30"
          rows="8"
          placeholder="Tell us more about your needs........"
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
      </div>
      <div className="ContactRightBtnDiv">
        <Button variant="contained" type="submit" disabled={state.submitting}>
          Submit
        </Button>
      </div>
    </form>
  );
}
export default ContactForm;
