import React, { useEffect } from "react";
import "../common.css";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const CommonHeadings = ({ commonheadingbgtxt, commonheading }) => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".commonheadingbgtxt");

      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        const progress = 60 * (rect.top / window.innerHeight);
        element.style.transform = `translateY(-${progress}%)`;
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="commonheadingDiv" data-aos="fade-up">
      <h1 className="commonheadingbgtxt">{commonheadingbgtxt}</h1>
      <h1 className="commonheading">{commonheading}</h1>
      <span className="greenBorder"></span>
    </div>
  );
};

export default CommonHeadings;
