import "./App.css";
import "../src/common.css";
import MiniDrawer from "./components/sideBar";
// import PaymentModal from "./paymentModal";
// import Loader from "./components/loader";
function App() {
  return (
    <>
      {/* <Loader /> */}
      <div className="parent">
        <MiniDrawer />
        {/* <PaymentModal open={true} /> */}
      </div>
    </>
  );
}

export default App;
