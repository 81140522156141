import React, { useEffect, useRef } from "react";
import "../common.css";
import bgImg from "../images/main-bg2.jpg";
import { Parallax } from "react-parallax";
import Typed from "typed.js";
import CvBtn from "./cvBtn";
import "animate.css";
const Home = () => {
  const typedElement = useRef(null);

  useEffect(() => {
    const options = {
      strings: [
        "Sharique Mangani",
        "a Frontend Engineer",
        "a Designer",
        "a Photographer",
      ],
      typeSpeed: 100,
      backSpeed: 30,
      backDelay: 2000,
      loop: true,
    };

    const typed = new Typed(typedElement.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <>
      <Parallax bgImage={bgImg} strength={350}>
        <div id="homeSection" className="homeMain">
          <div className="homeMainContent">
            <h2 className="animate__animated animate__backInUp">Welcome </h2>
            <h1 className="myContent animate__animated animate__backInUp ">
              I'm <span id="typed" ref={typedElement}></span>.
            </h1>
            <p className="animate__animated animate__backInUp ">
              Live in Tamil Nadu
            </p>
            <CvBtn inText="Hire Me" link="#contact" />
          </div>
        </div>
      </Parallax>
    </>
  );
};

export default Home;
