import React from "react";
import "../common.css";
import Home from "./home";
import About from "./about";
import WhatIDo from "./whatIDo";
import Resume from "./resume";
import Portfolio from "./portfolio";
import Testimonial from "./testimonial";
import Contact from "./contact";

const MainContent = () => {
  return (
    <>
      <div className="mainContentMain">
        <Home />
        <About />
        <WhatIDo />
        <Resume />
        <Portfolio />
        <Testimonial />
        <Contact />
      </div>
    </>
  );
};

export default MainContent;
