import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useInView } from "react-intersection-observer";
import "../common.css";

function LinearProgressWithLabel(props) {
  return (
    <>
      <Typography
        className="fillRange"
        variant="body2"
        color="text.secondary"
      >{`${Math.round(props.value)}%`}</Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            {...props}
            sx={{
              background: "#ddd",
              borderRadius: "5px",
              padding: "4px",
              "& .MuiLinearProgress-bar": {
                borderRadius: "5px",
                backgroundColor: "#20c997",
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const LinearWithValueLabel = ({ limit }) => {
  const [progress, setProgress] = useState(0);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= limit ? limit : prevProgress + 5
        );
      }, 150);

      return () => {
        clearInterval(timer);
      };
    }
  }, [inView, limit]);

  return (
    <div ref={ref} style={{ minHeight: "1px", position: "relative" }}>
      <Box sx={{ width: "100%" }}>
        <LinearProgressWithLabel value={progress} />
      </Box>
    </div>
  );
};

export default LinearWithValueLabel;
