import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonialStyles.css";
import { ClientFeedback } from "../datas";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const ClientSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-parent" data-aos="fade-up">
      <div>
        <Slider {...settings}>
          {ClientFeedback?.map((client) => {
            return (
              <div>
                <div className="sliderChilds">
                  <div className="sliderChildsHeading">
                    <img
                      src={!client.img === "" ? client.img : "clientImage"}
                      alt=""
                      className="sliderChildsHeadingImg"
                    />
                    <div className="sliderChildsHeadingContent">
                      <h4>{client.name}</h4>
                      <p> From {client.from}</p>
                    </div>
                  </div>
                  <div className="sliderChildsBody">
                    <p className="clientFeedback">{client.feedback}</p>
                    <p>{client.ratings}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default ClientSlider;
