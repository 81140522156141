import inaamPic from "../images/inaamPic.jpg";
import mehranPic from "../images/mehranPic.jpg";
import raashidPic from "../images/raashidPic.jpeg";
import facebook from "../images/icons/facebook.svg";
import facebookActive from "../images/icons/facebookActive.svg";
import github from "../images/icons/github.svg";
import githubActive from "../images/icons/githubActive.svg";
import XTwitter from "../images/icons/XTwitter.svg";
import XTwitterActive from "../images/icons/XTwitterActive.svg";
export const ServiceIcons = [
  {
    className: "fa-solid fa-desktop whatIDoIcon",
    topic: "Web Development",
    content:
      "Proficient in building appealing websites with HTML, CSS, and JavaScript.Skillful in designing user-friendly interfaces for enhanced web experiences. Experienced in web development, utilizing HTML, CSS, and JavaScript to create functional and interactive websites.",
  },
  {
    className: "fa-solid fa-palette whatIDoIcon",
    topic: "Web Design",
    content:
      "Skilled in creating visually appealing and user-friendly web designs, prioritizing both aesthetics and functionality. Proficient in design tools like Adobe XD or Figma for crafting wireframes and polished web interfaces.",
  },
];

export const MyDetails = [
  {
    content: "Name : ",
    text: "Sharique Mangani",
    color: "#454545",
  },
  {
    content: "Email : ",
    text: "shariquemangani03@gmail.com",
    color: "#20c997",
  },
  {
    content: "Age : ",
    text: " 23",
    color: "#454545",
  },
  {
    content: "From : ",
    text: " Pernambut, Tamil Nadu",
    color: "#454545",
  },
];
export const MyEducation = [
  {
    year: "2017 – 2019",
    feild: "Commerce",
    location: "Dr Bapuji salunkhe Collage, Miraj",
    text: "Studied commerce in 11th and 12th grades, focusing on subjects like Accounting and Economics. Gained practical experience in financial concepts and analysis during high school.",
  },
  {
    year: "2019 - 2022",
    feild: "Bachelor Of Computer Application",
    location: "Miraj Mahavidayalaya, Miraj.",
    text: "Completed BCA with a focus on web development languages like HTML, CSS, and JavaScript. Applied practical knowledge in creating dynamic websites through coursework and real-world projects. ",
  },
];

export const MyExperience = [
  {
    year: "July 2023 - Present",
    feild: "React Development",
    location: "Colan InfoTech PVT LDT",
    text1:
      "Demonstrated proficiency in HTML, CSS, and JavaScript, contributing to the development of responsive and visually appealing user interfaces for web applications.",
    text2:
      "Leveraged one year of experience in React to build dynamic and interactive components, enhancing the overall user experience and optimizing application performance.Successfully collaborated with cross-functional teams to implement front-end features, ensuring seamless integration of design elements and adherence to best practices in web development.",
    text3:
      "Successfully collaborated with cross-functional teams to implement front-end features, ensuring seamless integration of design elements and adherence to best practices in web development.",
  },
];

export const MySkills = [
  {
    field: "Next JS",
    value: "60",
  },
  {
    field: "React JS",
    value: "60",
  },
  {
    field: "TypeScript",
    value: "50",
  },
  {
    field: "JavaScript",
    value: "70",
  },
  {
    field: "Tailwind",
    value: "90",
  },
  {
    field: "Bootstrap",
    value: "90",
  },
  {
    field: "CSS 3",
    value: "95",
  },
  {
    field: "HTML 5",
    value: "95",
  },
  {
    field: "Figma",
    value: "70",
  },
];

export const ClientFeedback = [
  {
    img: raashidPic,
    name: "Raashid Aamina",
    from: "Pernambut",
    feedback: `“Consistently delivered high-quality code, showcasing a solid understanding of web development principles and contributing positively to project outcomes.”`,
    ratings: "⭐⭐⭐⭐⭐",
  },
  {
    img: inaamPic,
    name: "Inamul Hassan",
    from: "Gudiyattam",
    feedback: `“Demonstrated adaptability by quickly grasping new technologies and efficiently applying them in projects, reflecting a commitment to continuous learning and improvement.”`,
    ratings: "⭐⭐⭐⭐⭐",
  },
  {
    img: mehranPic,
    name: "Mohammad Mehran",
    from: "Ambur",
    feedback: ` "Proven ability to work collaboratively in a team environment, actively participating in discussions and providing valuable insights that contributed to the overall success of web development projects.”`,
    ratings: "⭐⭐⭐⭐⭐",
  },
  {
    img: "",
    name: "Imraan",
    from: "Ambur",
    feedback: ` “Displayed strong problem-solving skills, addressing challenges with creativity and resourcefulness, resulting in effective and timely resolution of issues during the development lifecycle.”`,
    ratings: "⭐⭐⭐⭐⭐",
  },
];

export const ContactLinks = [
  {
    img1: github,
    img2: githubActive,
    title: "Github",
    location: "https://github.com/shariquemangani",
  },
  {
    img1: facebook,
    img2: facebookActive,
    title: "Facebook",
    location: "https://www.facebook.com/shariquemangani/",
  },
  {
    img1: XTwitter,
    img2: XTwitterActive,
    title: "Twitter",
    location: "https://twitter.com/shariquemangani/",
  },
];
